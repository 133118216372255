div.About {
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    top: 5rem;
    color: white;
    height: 50vh;
}

div.About div.About-header{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 70vw;
}

div.About div.About-header div.About-text{
    width: 25vw;
    font-size: 1.2em;
}

div.About div.About-intro img.About-logo{
    width: 12vw;
    height: auto;
}

div.About div.About-header div.About-buttons button{
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid yellow;
    color: white;
    
    
    width: 40%;
    border-radius: 10px;
    transition: .5s;
    margin: 10px
}

div.About div.About-header button:hover{
    color: black;
    background-color: rgb(255, 255, 96);
    width: 8%;
}

div.About div.About-intro img.About-logo.React{
    animation: rotate 15s infinite linear;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to{
        transform: rotate(359deg);
    }
    
}